import React, { useState, useEffect } from 'react';
import { UserData, SurveyData, AnalysisResult } from './types';
import { v4 as uuidv4 } from 'uuid';
import { analyzeResults } from './utils/analysis';
import LandingPage from './components/LandingPage';
import WelcomePage from './components/WelcomePage';
import Survey from './components/Survey';
import Results from './components/Results';
import ErrorBoundary from './components/ErrorBoundary';
import { VERSION } from './config/version';

function App() {
  const [currentPage, setCurrentPage] = useState<'landing' | 'welcome' | 'survey' | 'results' | 'feedback'>('landing');
  const [userData, setUserData] = useState<UserData | null>(null);
  const [analysisResult, setAnalysisResult] = useState<AnalysisResult | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId] = useState(() => uuidv4());

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch('/api/version');
        const { version } = await response.json();

        if (version !== VERSION.displayVersion) {
          // Force reload if versions don't match
          window.location.reload();
        }
      } catch (error) {
        console.error('Version check failed:', error);
      }
    };

    // Check version on mount and every 5 minutes
    checkVersion();
    const interval = setInterval(checkVersion, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const handleUserDataSubmit = (data: UserData) => {
    setUserData(data);
    setCurrentPage('welcome');
  };

  const handleWelcomeContinue = () => {
    setCurrentPage('survey');
  };

  const handleSurveyComplete = (data: SurveyData) => {
    setIsLoading(true);
    try {
      const result = analyzeResults(data);
      setAnalysisResult(result);
      setCurrentPage('results');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFeedbackSubmit = (feedback: string) => {
    console.log('Feedback submitted:', feedback);
  };

  const renderPage = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    switch (currentPage) {
      case 'landing':
        return <LandingPage onSubmit={handleUserDataSubmit} sessionId={sessionId} />;
      case 'welcome':
        return userData ? (
          <WelcomePage
            userData={userData}
            onContinue={handleWelcomeContinue}
            sessionId={sessionId}
          />
        ) : null;
      case 'survey':
        return userData ? (
          <Survey
            userData={userData}
            onComplete={handleSurveyComplete}
            sessionId={sessionId}
          />
        ) : null;
      case 'results':
        return analysisResult && userData ? (
          <Results
            results={analysisResult}
            userData={{
              name: userData.name,
              sessionId: sessionId,
              accessCode: userData.accessCode
            }}
            onFeedbackSubmit={handleFeedbackSubmit}
          />
        ) : null;
      case 'feedback':
        return null;
    }
  };

  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-background">
        {renderPage()}
      </div>
    </ErrorBoundary>
  );
}

export default App;
