interface TypeDescription {
  title: string;
  detailedDescription: string;
  characteristics: string[];
  challenges: string[];
}

export const typeDescriptionDetails: Record<number, TypeDescription> = {
  1: {
    title: "Type 1 - The Personal Counselor",
    detailedDescription: `
      You have an innate ability to understand your emotions and can easily make good judgment calls about other people (such as listening to your "bad vibes" alert about a new coworker, being aware that being around a specific friend makes you feel happy, or identifying the source of feeling down). You augment and support this natural skill with a strong interest and focus on exploring new and unusual perspectives coupled with a deep understanding of the impact over time related to this skill.


      You have a potential ability to grow in identifying ways to increase your internal state of being through your senses. If you have not already begun to embrace and develop this skill, it is never too late. While continuously exposed to others' ability to evaluate the value and application of logic, you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don't let your strong skills with abstract reasoning and perspective become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time identifying ways you can improve your physical and mental state with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are focused on finding economic leverage, being entrepreneurial, and evaluating efficacy of objects or ideas. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of social cohesion and engagement with groups; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to maintain consistency through rules and processes at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in setting and maintaining pressure or boundaries. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  2: {
    title: "Type 2 - The Creative Visionary",
    detailedDescription: `
      You have an innate ability to understand novel and abstract ideas and perspectives (such as political philosophy, innovative approaches to problems at work, and considering the many unknowns and what-ifs of a situation) and can easily convey these to others. You augment and support this natural skill with a strong interest and focus on providing assistance to your close friends as well as with social groups related to this skill.

      You have a potential ability to grow in the evaluation of quality and applicability of objects or ideas. If you have not already begun to embrace and develop this skill, it is never too late.
      While continuously exposed to others’ ability to create comfortable and peaceful experiences, you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with evaluating the value of your individual relationships become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time leveraging economic opportunities with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are in tune with their senses, aesthetic, and calming. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of planning for and understanding long-term consequences; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to maintain boundaries and apply pressure at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in maintaining consistency and systematically following processes. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  3: {
    title: "Type 3 - The Master Artisan",
    detailedDescription: `
      You have an innate ability to understand how to improve the state of the human mind and body (such as how the temperature or ambient noises can impact comfort) and can easily adjust accordingly. You augment and support this natural skill with a strong interest and focus on the applicability and usefulness as well as the systematic patterns related to this skill.

      You have a potential ability to grow in perceiving how other people or their actions make you feel. If you have not already begun to embrace and develop this skill, it is never too late. While continuously exposed to others’ ability to create innovative and novel ideas, you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with evaluating the usefulness and value of objects or ideas become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time developing internal emotional awareness with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are philosophical explorers, inventors, and brainstormers of novel ideas. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of creating boundaries and applying pressure; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to plan for and consider long-term consequences at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in connecting with groups through being emotionally expressive. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  4: {
    title: "Type 4 - The Director and Artisan",
    detailedDescription: `
      You have an innate ability to understand the applicability and quality of objects or ideas and can easily sort the good from bad (such as evaluating vehicle reliability versus cost, comparing the immediate value of two work projects, or understanding how a big purchase would improve my day-to-day life). You augment and support this natural skill with a strong interest and focus on offering insight about comfortable experiences such as hosting events, preparing meals, and providing aesthetic insights–which you can provide with decisiveness and certainty.

      You have a potential ability to grow in developing unique and novel ideas and insights–if you have not begun to embrace and develop this skill, it is never too late. While continuously exposed to others’ ability to understand their own emotions (and therefore the quality of other people), you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills in adjusting your own physical and mental wellbeing or the wellbeing of others become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time expressing novel ideas and insights with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are authentic, unwavering, and have deep emotional awareness. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of the structure, organization, and balance of systems and processes; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to emotionally engage in group settings at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in identifying and preparing for long-term consequences. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  5: {
    title: "Type 5 - The Host and Artisan",
    detailedDescription: `
      You have an innate ability to understand how to improve the state of the human mind and body (such as how the temperature or ambient noises can impact comfort) and can easily adjust accordingly. You augment and support this natural skill with a strong interest and focus on engaging with social groups and encouraging cohesion between individuals, often offering emotional insight and advice to individuals related to this skill.

      You have a potential ability to grow in understanding and incorporating rules-based structure and organization in your life. If you haven’t yet embraced and developed this skill, it is never too late. While continuously exposed to others’ ability to create innovative and novel ideas, you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with social engagement become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time organizing and structuring your life with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are philosophical explorers, inventors, and brainstormers of novel ideas. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of creating boundaries and applying pressure; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to plan for and consider long-term consequences at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in understanding the value and context of objects or ideas. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  6: {
    title: "Type 6 - The Social Synergist",
    detailedDescription: `
      You have an innate ability to understand social frameworks (such as religious groups, friend circles, and social groups at work) and can easily navigate them. You augment and support this natural skill by offering insight about experiences where human comfort is your focus, such as in hosting events or preparing meals. You are also skilled in providing aesthetic insights– which you can provide with decisiveness and certainty related to this skill.

      You have a potential ability to grow in developing unique and novel ideas and insights. While continuously exposed to others’ ability to create structure and rule-based logic (such as maintaining consistent financial planning and organization), you will gain insight and ability on how to more effectively apply this skill yourself. If you haven’t yet embraced and developed this skill, it is never too late.
    `,
    characteristics: [
      "Don’t let your strong skills in adjusting your own physical and mental wellbeing or the wellbeing of others become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time expressing novel ideas and insights with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you. Try to hang around individuals who are consistent, structured, and rule-based. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of developing and maintaining close relationships where you can share personal emotions; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to evaluate the quality and value of systems or objects at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible. Be at peace with your difficulty in identifying and preparing for long-term consequences. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  7: {
    title: "Type 7 - The Analytical Designer",
    detailedDescription: `
      You have an innate ability to understand logical structures and can easily see how they overlap or relate to other structures (such as how the payroll system at work interacts with IT, complicated mechanical devices, or the interaction between macroeconomics and an economy). You augment and support this natural skill with a strong interest and focus on exploring new and unusual perspectives coupled with a deep understanding of the impact over time related to this skill.

      You have a potential ability to grow in identifying ways to increase your internal state of being through your senses. If you have not yet embraced or developed this skill, it is never too late. While continuously exposed to others’ ability to create fun and engaging environments, you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with abstract reasoning and perspective become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time identifying ways you can improve your physical and mental state with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are expressive, socially encouraging, and engaging. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of the value and applicability of objects and ideas; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to develop emotional connections with individuals at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in setting and maintaining pressure or boundaries. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  8: {
    title: "Type 8 - The Enterprising Visionary",
    detailedDescription: `
      You have an innate ability to understand novel and abstract ideas and perspectives (such as political philosophy, innovative approaches to problems at work, and considering the many unknowns and what-ifs of a situation) and can easily convey these to others. You augment and support this natural skill with a strong interest and focus on logical rulesets and structure as well as a keen awareness of the potential applicability of these structures related to this skill. 

      You have a potential to effectively integrate into and actively participate within various social groups. If you haven’t yet embraced and developed this skill, it is never too late. While continuously exposed to others’ ability to create comfortable and peaceful experiences, you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with creating structure, order, and rules become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time being openly expressive with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are in tune with their senses, aesthetic, and calming. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of planning for and understanding long-term consequences; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to maintain boundaries and apply pressure at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in understanding and developing the nuances of personal relationships. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  9: {
    title: "Type 9 - The Discerning Advisor",
    detailedDescription: `
      You have an innate ability to understand long-term threats and their consequences (such as the probable obstacles of a long-term project, future health-risks around a hobby, or how a career may unfold) and can easily identify them. You augment and support this natural skill with a strong interest and focus on engaging with social groups and encouraging cohesion between individuals, often offering emotional insight and advice to individuals related to this skill.

      You have a potential ability to grow in understanding and incorporating rules-based structure and organization in your life. If you have not already begun to embrace and develop this skill, it is never too late. While continuously exposed to others’ ability to take decisive action to immediate needs you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with social engagement become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time organizing and structuring your life with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are action-oriented, decisive, and strong-willed. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of abstract thinking about possibilities; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to take care of the physical and mental state of yourself or others at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in understanding the value and context of objects or ideas. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  10: {
    title: "Type 10 - The Social Director",
    detailedDescription: `
        You have an innate ability to understand social frameworks (such as religious groups, friend circles, and social groups at work) and can easily navigate them. You augment and support this natural skill with a strong interest and focus on understanding how its elements can evolve over time and the many potential perspectives related to this skill.

        You have a potential ability to grow in Identifying and decisively responding to immediate challenges of yours or others. If you have not already begun to embrace and develop this skill, it is never too late. While continuously exposed to structure and rule-based logic (such as maintaining consistent financial planning and organization), you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don't let your strong skills with identifying long-term outcomes become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time taking immediate and decisive action with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are consistent, structured, and rule-based. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of developing and maintaining close relationships where you can share personal emotions; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to evaluate the quality and value of systems or objects at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in understanding the state of wellbeing of your mind and body (or that of others). Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  11: {
    title: "Type 11 - The Master of Systems",
    detailedDescription: `
      You have an innate ability to understand logical structures and can easily see how they overlap or relate to other structures (such as how the payroll system at work interacts with IT, complicated mechanical devices, or the interaction between macroeconomics and an economy). You augment and support this natural skill with a strong interest and focus on dealing with immediate threats, identifying power dynamics, and maintaining your stability related to this skill.

      You have a potential ability to grow in predicting long-term outcomes and consequences. If you have not already begun to embrace and develop this skill, it is never too late.
      While continuously exposed to others’ ability to create fun and engaging environments, you will gain insight and ability on how to more effectively apply this yourself.

    `,
    characteristics: [
      "Don’t let your strong skills with setting and adjusting boundaries and pressure become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time discussing future outcomes and consequences with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are expressive, socially encouraging, and engaging. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of the value and applicability of objects and ideas; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to develop emotional connections with individuals at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in considering novel and abstract ideas. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  12: {
    title: "Type 12 - The Organizer and Leader",
    detailedDescription: `
      You have an innate ability to identify the power dynamics around yourself (such as the leader of a social circle, threatening behavior of an individual, or immediate physical danger) and can easily tackle or react to them head-on. You augment and support this natural skill with a strong interest and focus on logical rulesets and structure as well as a keen awareness of the potential applicability of these structures related to this skill.

      You have a potential ability to grow in the ability to effectively integrate into and actively participate within various social groups. If you have not already begun to embrace and develop this skill, it is never too late. While continuously exposed to others’ ability to consider and predict distant future outcomes, you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with creating structure, order, and rules become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time being openly expressive with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are philosophical, future-thinking, and frequently predicting outcomes. This will both energize you and help you develop these skills for yourself."

    ],
    challenges: [
      "Appreciate the importance of being in touch with your physical and mental state; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to consider abstract ideas and provide novelty at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in understanding and developing the nuances of personal relationships. Find a way to outsource this through other people who can provide you with specific advice and guidance."

    ]
  },
  13: {
    title: "Type 13 - The Just Guardian",
    detailedDescription: `
      You have an innate ability to understand your emotions and can easily make good judgment calls about other people (such as listening to your “bad vibes” alert about a new coworker, being aware that being around a specific friend makes you feel happy, or identifying the source of feeling down). You augment and support this natural skill with a strong interest and focus on dealing with immediate threats, identifying power dynamics, and maintaining your stability related to this skill.

      You have a potential ability to grow in predicting long-term outcomes and consequences. If you have not already begun to embrace and develop this skill, it is never too late.
      While continuously exposed to others’ ability to evaluate the value and application of logic, you will gain insight and ability on how to more effectively apply this yourself.

    `,
    characteristics: [
      "Don’t let your strong skills with setting and adjusting boundaries and pressure become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time discussing future outcomes and consequences with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are focused on finding economic leverage, being entrepreneurial, and evaluating efficacy of objects or ideas. This will both energize you and help you develop these skills for yourself."

    ],
    challenges: [
      "Appreciate the importance of social cohesion and engagement with groups; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to maintain consistency through rules and processes at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in considering novel and abstract ideas. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  14: {
    title: "Type 14 - The Opportunist and Diplomat",
    detailedDescription: `
      You have an innate ability to identify the power dynamics around yourself (such as the leader of a social circle, threatening behavior of an individual, or immediate physical danger) and can easily tackle or react to them head-on. You augment and support this natural skill with a strong interest and focus on providing assistance to your close friends as well as with social groups related to this skill.

      You have a potential ability to grow in the evaluation of quality and applicability of objects or ideas. If you have not already begun to embrace and develop this skill, it is never too late. While continuously exposed to others’ ability to consider and predict distant future outcomes, you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with evaluating the value of your individual relationships become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time leveraging economic opportunities with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are philosophical, future-thinking, and frequently predicting outcomes. This will both energize you and help you develop these skills for yourself."
    ],
    challenges: [
      "Appreciate the importance of being in touch with your physical and mental state; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to consider abstract ideas and provide novelty at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in maintaining consistency and systematically following processes. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  15: {
    title: "Type 15 - The Strategic Advisor",
    detailedDescription: `
      You have an innate ability to understand long-term threats and their consequences (such as the probable obstacles of a long-term project, future health-risks around a hobby, or how a career may unfold) and can easily identify them. You augment and support this natural skill with a strong interest and focus on the applicability and usefulness as well as the systematic patterns related to this skill.

      You have a potential ability to grow in perceiving how other people or their actions make you feel. If you have not already begun to embrace and develop this skill, it is never too late. While continuously exposed to others’ ability to take decisive action to immediate needs you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with evaluating the usefulness and value of objects or ideas become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time developing internal emotional awareness with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are action-oriented, decisive, and strong-willed. This will both energize you and help you develop these skills for yourself."

    ],
    challenges: [
      "Appreciate the importance of abstract thinking about possibilities; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to take care of the physical and mental state of yourself or others at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in connecting with groups through being emotionally expressive. Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  },
  16: {
    title: "Type 16 - The Tactical Enterpriser",
    detailedDescription: `
      You have an innate ability to understand the applicability and quality of objects or ideas and can easily sort the good from bad (such as evaluating vehicle reliability versus cost, comparing the immediate value of two work projects, or understanding how a big purchase would improve my day-to-day life). You augment and support this natural skill with a strong interest and focus on understanding how its elements can evolve over time and the many potential perspectives related to this skill.

      You have a potential ability to grow in Identifying and decisively responding to immediate challenges of yours or others. If you have not already begun to embrace and develop this skill, it is never too late. While continuously exposed to others’ ability to understand their own emotions (and therefore the quality of other people), you will gain insight and ability on how to more effectively apply this yourself.
    `,
    characteristics: [
      "Don’t let your strong skills with identifying long-term outcomes become an obsession; despite the enjoyment you gain from it, this requires energy and should be considered an augment to your natural skillset.",
      "Spend some time taking immediate and decisive action with others. Doing so may require significant energy if applied continually, but being comfortable with this skill will benefit you.",
      "Try to hang around individuals who are authentic, unwavering, and have deep emotional awareness. This will both energize you and help you develop these skills for yourself."

    ],
    challenges: [
      "Appreciate the importance of the structure, organization, and balance of systems and processes; you are already skilled with this. Don’t be dismissive or judgmental of your own skills here or of those who express this skill.",
      "Accept that you will be required to emotionally engage in group settings at times. This skill is necessary to accept and not ignore, but requires significant energy to perform; your energy should usually go elsewhere when possible.",
      "Be at peace with your difficulty in understanding the state of wellbeing of your mind and body (or that of others). Find a way to outsource this through other people who can provide you with specific advice and guidance."
    ]
  }
};

// Helper function to get description
export function getTypeDescription(type: number): TypeDescription | undefined {
  return typeDescriptionDetails[type];
} 