import React, { useState, useRef, useEffect } from "react";
import {
  SurveyQuestion,
  SurveyAnswer,
  AnalysisResult,
  UserData,
  SurveyData
} from "../types";
import { analyzeResults } from "../utils/analysis";
import { logger } from "../utils/logger";
import Results from "./Results";
import Tooltip from "./Tooltip";
import { v4 as uuidv4 } from 'uuid';
import { config } from '../config/environment';
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import { Progress } from "./ui/progress";
import { Input } from "./ui/input";
import { cn } from "../lib/utils";
import { VERSION } from '../config/version';
import { api } from '../utils/api';

// Section 1: Questions 1,2,5,6 only
const section1Questions: SurveyQuestion[] = [
  {
    id: 1,
    text: "1. You attend a networking lunch and are assigned to sit at a table of 10 people whom you do not know. Which social approach do you prefer?",
    optionA:
      "Someone suggests an ice breaker where people introduce themselves by sharing a fun fact and their current company. Everyone is encouraged to engage in the conversation.",
    optionB:
      "Someone beside you introduces themself. They ask you about yourself and seem to want to get to you know more. The table consists of several of these one-on-one conversations.",
    onSelect: (option: "A" | "B") => {
      logger.log(`1.${option.toLowerCase()}`);
    }
  },
  {
    id: 2,
    text: "2. You work with a non-profit program and are assisting a coworker in developing guidelines for interviewing volunteers. Which coworker would you prefer to work with?",
    optionA:
      "Someone who adopts a generalized approach to 'cut the red tape,' thus allowing the interviewers to be more flexibile and adaptable. Increased autonomy will result in a more efficient recruiting process.",
    optionB:
      "Someone who adopts a detailed and thorough approach to ensure accuracy, thus increasing recruiting efficiency. The entire process will be documented and used as a reference by others.",
    onSelect: (option: "A" | "B") => {
      logger.log(`2.${option.toLowerCase()}`);
    }
  },
  {
    id: 3,
    text: "3. You are invited to a friend's wedding. Which type of social wedding atmosphere would you prefer?",
    optionA:
      "Vibrant music and boisterous, lighthearted, funny toasts that allow guests to share in the couple's celebration. You enjoy casual mingling and may keep it brief to interact with more people.",
    optionB:
      "Intimate, engaging atmosphere and sincere toasts. You enjoy one-on-one conversations where a deeper connection may exist. You may keep it brief with people you don't know well.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.${option.toLowerCase()}`);
    }
  },
  {
    id: 4,
    text: "4. A team of students is seeking your advice on a group presentation. Which group do you prefer to advise?",
    optionA:
      "A group where each person wants to be assigned a specific task and process they must complete thoroughly. They don't want to miss any details, though might end up doing some extra work.",
    optionB:
      "A group who wants help identifying the top factors that may result in the most sizeable impact. Members can complete the work how they see fit, but this approach might miss some important details.",
    onSelect: (option: "A" | "B") => {
      logger.log(`4.${option.toLowerCase()}`);
    }
  },
];

// Section 2: Questions 9,10,13,14,17,18,21,22 only
const section2Questions: SurveyQuestion[] = [
  {
    id: 5,
    text: "5. Which of these skills comes most naturally to you?",
    optionA:
      "Understanding and adjusting the emotional atmosphere of a group.\n\nEx. Bringing excitement to a group at a birthday party as you tend to be able to read the room and adapt accordingly.",
    optionB:
      "Understanding and adjusting highly complicated logical structures.\n\nEx. Learning the principles behind income taxes as it follows complex, yet repeatable patterns.",
    onSelect: (option: "A" | "B") => {
      logger.log(`5.${option.toLowerCase()}`);
    }
  },
  {
    id: 6,
    text: "6. Which of these skills comes most naturally to you?",
    optionA:
      "Understanding and adjusting the emotional distance of your individual relationships.\n\nEx. Expressing a specific opinion to your best friend in order to feel closer.",
    optionB:
      "Understanding and adjusting specific components in a system to increase efficiency.\n\nEx. Preparing ingredients and utensils before you start cooking to streamline cooking and cleaning.",
    onSelect: (option: "A" | "B") => {
      logger.log(`6.${option.toLowerCase()}`);
    }
  },
  {
    id: 7,
    text: "7. Which of these skills comes most naturally to you?",
    optionA:
      "Communicating with other people through adjusting your tone-of-voice and facial expressions.\n\nEx. Knowing when to use a soft or firm tone in order to encourage a team that is falling behind.",
    optionB:
      "Communicating with other people through consistent explanations and systematic logical principles.\n\nEx. Explaining the concepts and rules of a game to a group of people who haven't played it before.",
    onSelect: (option: "A" | "B") => {
      logger.log(`7.${option.toLowerCase()}`);
    }
  },
  {
    id: 8,
    text: "8. Which of these skills comes most naturally to you?",
    optionA:
      "Interpreting your opinions and how those opinions make you feel.\n\nEx. Considering how a house in a neighborhood makes you feel to determine an ideal house to buy.",
    optionB:
      "Interpreting data points and individual facts.\n\nEx. Reviewing an article about neighborhood amenities, Home Owner Association rules, and taxes to determine an ideal house to buy.",
    onSelect: (option: "A" | "B") => {
      logger.log(`8.${option.toLowerCase()}`);
    }
  },
  {
    id: 9,
    text: "9. Which type of advice are you more naturally skilled at providing?",
    optionA:
      "How to join social groups and engage in group activities.\n\nEx. Advising a friend on some rules-of-thumb to make connections at an upcoming wedding they plan to attend, such as scanning the room to see which groups are interesting before joining their conversation.",
    optionB:
      "How to understand the frameworks and principles of logistics in activities.\n\nEx. Advising a friend on how to plan travel to an upcoming wedding they plan to attend, such as providing a travel checklist, a budget, and ensuring they have the appropriate identification.",
    onSelect: (option: "A" | "B") => {
      logger.log(`9.${option.toLowerCase()}`);
    }
  },
  {
    id: 10,
    text: "10. Which type of advice are you more naturally skilled at providing?",
    optionA:
      "How to develop a close relationship in order to make it more valuable.\n\nEx. Advising a coworker on how to pull back from a friendship that has been challenging for them.",
    optionB:
      "How to identify and compare business or economic opportunities.\n\nEx. Advising a coworker on how to compare different 401k investment options.",
    onSelect: (option: "A" | "B") => {
      logger.log(`10.${option.toLowerCase()}`);
    }
  },
  {
    id: 11,
    text: "11. Which type of advice are you more naturally skilled at providing?",
    optionA:
      "Managing social groups and the expectations of its members.\n\nEx. Advising a friend on how to reduce social friction in a challenging friend group.",
    optionB:
      "Managing personal finances by following rules and principles.\n\nEx. Advising a friend on how to improve their financial wellness by using strategies of budgeting, spending, and saving.",
    onSelect: (option: "A" | "B") => {
      logger.log(`11.${option.toLowerCase()}`);
    }
  },
  {
    id: 12,
    text: "12. Which type of advice are you more naturally skilled at providing?",
    optionA:
      "Managing the nuances and situational details of a close relationship.\n\nEx. Advising a family member on how to use their personality traits to better connect with another family member.",
    optionB:
      "Managing personal finances through leveraging unique opportunities.\n\nEx. Advising a family member on how to to take advantage of a recent change in the housing market.",
    onSelect: (option: "A" | "B") => {
      logger.log(`12.${option.toLowerCase()}`);
    }
  },
];

// Section 2.1: Questions 9,10,13,14,17,18,21,22 only
const section2_1Questions: Record<string, SurveyQuestion> = {
  "2_1_1": {
    id: 2.11,
    text: "The following best describes my approach to helping a friend who is feeling down:",
    optionA: "I rely on my engaging demeanor as a proactive way to encourage and uplift my friend. Knowing the specifics does not necessarily change my approach and may be a waste of energy.", //Cats + 1
    optionB: "I often ask specifics around their mood and am willing to take considerable time listening and comforting my friend. I am skilled in providing subtle advice in a supportive way.", //Dogs + 1
    onSelect: (option: "A" | "B") => {
      logger.log(`2.11.${option.toLowerCase()}`);
    }
  },
  "2_1_2": {
    id: 2.12,
    text: "When faced with a task that may take a few hours to complete, the following best describes my natural mode of operation:",
    optionA: "I take a step back to evaluate the big picture, ensuring I understand the overall structure, organization, and goals before diving into specific tasks. Even for routine tasks, I focus on the overall plan before considering the components as this ensures everything fits together properly.", //Lions + 1
    optionB: "I consider a few of the most important details of my tasks, or complete a few of the highest priority tasks first, then structure my plan around those points. I often act quickly for familiar tasks. I focus on the ‘why’ behind the task details to better understand the big picture. ", //Wolves + 1
    onSelect: (option: "A" | "B") => {
      logger.log(`2.12.${option.toLowerCase()}`);
    }
  },
  "2_1_3": {
    id: 2.13,
    text: "The following best describes my approach to helping a friend who is feeling down:",
    optionA: "I am skilled at asking the right questions and feeling out how I may or may not relate to their challenges. I prefer this approach over adjusting my expressions to alter their mood, as that may be a waste of energy.", //Dogs + 1
    optionB: "I take the time to listen and learn the details of their situation. Sometimes I will adjust my own emotional state or tailor my expressions to alter their mood if I believe it is necessary.", //Cats + 1
    onSelect: (option: "A" | "B") => {
      logger.log(`2.13.${option.toLowerCase()}`);
    }
  },
  "2_1_4": {
    id: 2.14,
    text: "When faced with a task that may take a few hours to complete, the following best describes my natural mode of operation:",
    optionA: "Quickly evaluate the work I need to accomplish as I often prioritize efficiency in completing the tasks. I can figure out the overall structure and organization of my task as needed, but this may be wasted effort.", //Wolves + 1
    optionB: "Carefully evaluate the work I need to accomplish, and I often find it useful to take time to consider my task within a broader context. I am skilled at doing both, but more so in stepping back to see how the parts make up the whole.", //Lions + 1
    onSelect: (option: "A" | "B") => {
      logger.log(`2.14.${option.toLowerCase()}`);
    }
  },
};

// Section 3: Branching questions
const section3Questions: Record<number, SurveyQuestion> = {
  3.1: {
    id: 3.1,
    text: "13. To increase group engagement and motivation, which approach do you prefer?",
    optionA:
      "Engage with (or become) the most influential person in a team to get buy-in for a specific goal. This ultimately helps the entire team align toward a central purpose.",
    optionB:
      "Assist in aligning the group's mood with the occasion, keeping event hospitality in mind. Ensure everyone's comfort by having snacks, a conducive environment or thoughtful, personalized touches for the group.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.1.${option.toLowerCase()}`);
    }
  },
  3.2: {
    id: 3.2,
    text: "14. When joining a large team, which approach you prefer?",
    optionA:
      "Apply learned or intuitive rules on how to behave depending on who you are around. Attach yourself to the key players within a group in order to influence their motivations and goals.",
    optionB:
      "Follow appropriate etiquette and maintain awareness of whom you are engaging with. Be flexible to their needs and provide the team whatever is needed, such as a positive attitude, decision-making, or some kind words.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.2.${option.toLowerCase()}`);
    }
  },
  3.3: {
    id: 3.3,
    text: "13. When organizing a brainstorming session to solve a complex problem, which approach do you prefer?",
    optionA:
      "Allow the experts to speak first thus reducing unnecessary speculation toward solving the problem. Any additional ideas from the group can be pre-screened and brought to the experts beforehand.",
    optionB:
      "Encourage group discussion to allow many perspectives. Rushing the discussion may result in important considerations being left out. Consider how the perspectives and ideas fit together into the big picture.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.3.${option.toLowerCase()}`);
    }
  },
  3.4: {
    id: 3.4,
    text: "14. In order to provide a vision for a team to follow, which approach do you prefer?",
    optionA:
      "Gather all necessary data points to understand what is possible, entirely reasonable, and immediately actionable. Involve experts. Document a detailed and specific plan with defined milestones.",
    optionB:
      "Ensure the plan has enough flexibility to integrate multiple paths forward. Bounce ideas off others, including experts. Document many ideas and refine along the way.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.4.${option.toLowerCase()}`);
    }
  },
  3.5: {
    id: 3.5,
    text: "13. Which approach do you prefer to motivate and give purpose to an individual?",
    optionA:
      "By listening and understanding a person's goals, I can encourage specific, immediate, and decisive action.",
    optionB:
      "By offering new, creative ideas and encouraging unique approaches to problems, perhaps I can energize a person into action.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.5.${option.toLowerCase()}`);
    }
  },
  3.6: {
    id: 3.6,
    text: "14. Which approach do you prefer to draw attention to an individual who is inappropriately influencing a group?",
    optionA:
      "By directly asking the individual the 'why' behind their suggestion, I can prompt them to explain their intentions and show the group there may be a specific motivation.",
    optionB:
      "By providing alternative perspectives to what the influencer is suggesting, I can present viable alternatives or even highlight their motivation for the group members to assess.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.6.${option.toLowerCase()}`);
    }
  },
  3.7: {
    id: 3.7,
    text: "13. Which approach do you prefer for household organization and cleanliness?",
    optionA:
      "Home efficiency is important to me. I appreciate quick and easy access to commonly-used items. The aesthetics of how this is accomplished is a lower priority than the organization itself. I rarely spend time cleaning or organizing unless I know guests are coming over.",
    optionB:
      "My home is my sanctuary and I keep it organized and tidy to give me peace of mind. Displaying meaningful experiences such as vacation photographs, trinkets and mementos, or favorite board games are the difference between a house and a home.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.7.${option.toLowerCase()}`);
    }
  },
  3.8: {
    id: 3.8,
    text: "14. To gain self-awareness and chart your personal growth path, which approach do you prefer?",
    optionA:
      "The steps I take today toward immediate milestones will dictate long-term future outcomes and play an important role in who I will become. This planning is enjoyable.",
    optionB:
      "My lineage, family history, and the path taken by family members all play an important role in who I am and who I will become. These topics are fascinating.",
    onSelect: (option: "A" | "B") => {
      logger.log(`3.8.${option.toLowerCase()}`);
    }
  },
};

interface SurveyProps {
  userData: UserData & {
    name: string;
  };
  sessionId: string;
  onComplete?: (data: SurveyData) => void;
}

const Survey: React.FC<SurveyProps> = ({ userData, onComplete, sessionId }) => {
  const [currentSection, setCurrentSection] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<SurveyAnswer[]>([]);
  const [results, setResults] = useState<AnalysisResult | null>(null);
  const [part1, setPart1] = useState<"A" | "B" | null>(null);
  const [part2, setPart2] = useState<"wide" | "small" | null>(null);
  const [part3, setPart3] = useState<"A" | "B" | "C" | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [questionFeedback, setQuestionFeedback] = useState<
    "easy" | "difficult" | null
  >(null);
  const [difficultyFeedback, setDifficultyFeedback] = useState<string>("");
  const feedbackRef = useRef<HTMLDivElement>(null);

  // Add ref for Next button
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const part1Ref = useRef<HTMLDivElement>(null);
  const part2Ref = useRef<HTMLDivElement>(null);
  const part3Ref = useRef<HTMLDivElement>(null);

  // Function to check if an element is fully visible
  const isElementFullyVisible = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  // Function to scroll to element if not fully visible
  const scrollToIfNeeded = (element: HTMLElement | null) => {
    if (element) {
      const headerOffset = 20;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  const handleQuestionFeedback = (feedback: "easy" | "difficult") => {
    logger.log('Question feedback selected:', feedback);
    setQuestionFeedback(feedback);
    scrollToIfNeeded(nextButtonRef.current);
  };

  // Add loading state
  const [isSyncing, setIsSyncing] = useState(false);

  // Update syncLocalStorage to use loading state
  const syncLocalStorage = async () => {
    try {
      setIsSyncing(true);
      const logs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
      if (logs.length > 0) {
        logger.log('Sending bulk logs:', logs);

        const response = await fetch('/api/telemetry/bulk', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any auth headers if needed
          },
          body: JSON.stringify({ logs }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(`API Error: ${data.error} - ${data.details}`);
        }

        // Only clear logs if the request was successful
        localStorage.removeItem('surveyLogs');
        logger.log('Successfully synced local logs');
      }
    } catch (error) {
      logger.error('Error syncing local logs:', error);
      // Don't remove logs on error so we can retry later
    } finally {
      setIsSyncing(false);
    }
  };

  // Add loading state
  const [isSendingTelemetry, setIsSendingTelemetry] = useState(false);

  // const validateTelemetryData = (data: any) => {
  //   let isValid = true;
  //   if (!data.userData?.name) {
  //     logger.warn('Warning: Invalid telemetry data - missing userData.name:', data);
  //     isValid = false;
  //   }
  //   if (!data.message_name || !data.questionId) {
  //     logger.warn('Warning: Invalid telemetry data - missing required fields:', data);
  //     isValid = false;
  //   }
  //   return isValid;
  // };

  // Update logTelemetry function to not block on validation failure
  const logTelemetry = async (answer: SurveyAnswer) => {
    logger.log('logTelemetry called with answer:', answer);
    setIsSendingTelemetry(true);
    try {
      const telemetryData = {
        userData: {
          name: userData.name,
          accessCode: userData.accessCode
        },
        message_name: 'answer_submitted' as const,
        questionId: String(answer.questionId),
        part1: answer.part1,
        part2: answer.part2,
        part3: answer.part3,
        questionFeedback: answer.questionFeedback,
        difficultyFeedback: answer.difficultyFeedback || '',
        timestamp: new Date().toISOString(),
        metadata: {
          sessionId: sessionId,
          browser: navigator.userAgent,
          url: window.location.href,
          buildNumber: VERSION.displayVersion,
          buildDate: VERSION.buildDate,
          environment: VERSION.environment
        }
      };

      logger.log('Attempting to send telemetry:', telemetryData);

      try {
        logger.log('Making API call to telemetry endpoint');
        await api.sendTelemetry(telemetryData);
      } catch (error) {
        logger.error('API call error:', error);
        // Store in localStorage for retry
        const existingLogs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
        existingLogs.push(telemetryData);
        localStorage.setItem('surveyLogs', JSON.stringify(existingLogs));
        logger.log('Stored telemetry in localStorage for retry later');
      }
    } finally {
      setIsSendingTelemetry(false);
    }
  };

  // Modify handleNext to properly handle section transitions
  const handleNext = async () => {
    setIsSubmitting(true);
    logger.log('handleNext called with current state:', {
      currentSection,
      currentQuestion,
      part1,
      part2,
      part3,
      questionFeedback,
      difficultyFeedback
    });

    if (!part1 || !part2 || !part3) {
      logger.warn('Missing answer parts:', { part1, part2, part3 });
      setIsSubmitting(false);
      return;
    }

    const question = getCurrentQuestion();
    if (!question) {
      logger.error('No current question found');
      return;
    }

    logger.log('Current question:', question);

    const newAnswer: SurveyAnswer = {
      questionId: question.id,
      part1,
      part2,
      part3,
      questionFeedback,
      difficultyFeedback: questionFeedback === "difficult" ? difficultyFeedback : "",
    };

    logger.log('Created new answer:', newAnswer);

    try {
      logger.log('Sending telemetry...');
      await logTelemetry(newAnswer);
      logger.log('Telemetry sent successfully');
    } catch (error) {
      logger.error('Failed to send telemetry:', error);
    }

    const updatedAnswers = [...answers, newAnswer];
    setAnswers(updatedAnswers);
    logger.log('Updated answers array:', updatedAnswers);

    // Reset form
    setPart1(null);
    setPart2(null);
    setPart3(null);
    setQuestionFeedback(null);
    setDifficultyFeedback("");
    logger.log('Form state reset');

    // Navigation logic with logging
    const currentQuestions = getCurrentSectionQuestions();
    logger.log('Navigation state:', {
      currentSection,
      currentQuestion,
      totalQuestions: currentQuestions.length
    });

    // Check for Unknown QPrefD after section 1
    if (currentSection === 1 && currentQuestion === currentQuestions.length - 1) {
      const partialResults = analyzeResults({
        answers: updatedAnswers,
      });

      if (partialResults.QPrefD === "Unknown") {
        // Reset section 1 answers and start over
        setAnswers([]);
        setCurrentSection(1);
        setCurrentQuestion(0);
        setIsSubmitting(false);  // Reset submitting state
        // Add message about adjusting gaps
        setMessage("Please consider adjusting your gaps - in theory some of these should be narrow while others should be wide.");
        return;
      }
    }

    // Navigation logic
    if (currentQuestion < currentQuestions.length - 1) {
      logger.log('Moving to next question in current section');
      setCurrentQuestion(currentQuestion + 1);
      setMessage(null);
    } else if (currentSection === 2) {
      logger.log('Analyzing results for section 2 transition');
      const partialResults = analyzeResults({
        answers: updatedAnswers,
      });
      logger.log('Partial results:', partialResults);
      setMessage(null);

      const mpoldeScore =
        Math.abs(partialResults.s2dogs) +
        Math.abs(partialResults.s2wolves) -
        (Math.abs(partialResults.s2cats) + Math.abs(partialResults.s2lions));

      logger.log('MPolDe score:', mpoldeScore);

      if (mpoldeScore === 0) {
        logger.log('Moving to section 2.1');
        setCurrentSection(2.1);
        setCurrentQuestion(0);
      } else {
        logger.log('Moving to section 3');
        setCurrentSection(3);
        setCurrentQuestion(0);
      }
    } else if (currentSection === 2.1) {
      logger.log('Moving from section 2.1 to section 3');
      setCurrentSection(3);
      setCurrentQuestion(0);
    } else if (currentSection < 3) {
      logger.log('Moving to next section');
      setCurrentSection(currentSection + 1);
      setCurrentQuestion(0);
    } else {
      logger.log('Survey complete, analyzing final results');
      const surveyData = {
        answers: updatedAnswers,
      };
      const analysisResult = analyzeResults(surveyData);
      logger.log('Final analysis result:', analysisResult);

      // Check if section 3 scores are equal
      if (currentSection === 3 &&
        currentQuestion === currentQuestions.length - 1 &&
        analysisResult.QPrefL === "Unknown") {
        // Remove section 3 answers and start over
        const section1and2Answers = updatedAnswers.slice(0, -2); // Remove last 2 answers (section 3)
        setAnswers(section1and2Answers);
        setCurrentSection(3);
        setCurrentQuestion(0);
        setIsSubmitting(false);
        setMessage("Please consider adjusting your answers for the final questions - try to identify which style you prefer more strongly.");
        return;
      }

      setResults(analysisResult);
      onComplete?.(surveyData);
    }
    setTimeout(function () {
      // Force layout recalculation
      document.body.style.minHeight = '100vh';
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'  // Use instant instead of smooth for consistent behavior
      });
      setIsSubmitting(false);
    }, 100);  // Increased timeout to ensure DOM is ready
  };

  const getCurrentSectionQuestions = () => {
    logger.log('Getting questions for section:', currentSection);

    if (currentSection === 1) {
      logger.log('Returning section 1 questions');
      return section1Questions;
    }
    if (currentSection === 2) {
      logger.log('Returning section 2 questions');
      return section2Questions;
    }

    if (currentSection === 2.1) {
      const partialResults = analyzeResults({ answers: answers.slice(0, 12) });
      const { QPrefD, QStrD, MPolDe } = partialResults;

      const mpoldeScore =
        Math.abs(partialResults.s2dogs) +
        Math.abs(partialResults.s2wolves) -
        (Math.abs(partialResults.s2cats) + Math.abs(partialResults.s2lions));

      if (mpoldeScore === 0) {
        if (QPrefD === "Feline" && QStrD === "Pets")
          return [section2_1Questions["2_1_1"]];
        if (QPrefD === "Feline" && QStrD === "Wild")
          return [section2_1Questions["2_1_2"]];
        if (QPrefD === "Canine" && QStrD === "Pets")
          return [section2_1Questions["2_1_3"]];
        if (QPrefD === "Canine" && QStrD === "Wild")
          return [section2_1Questions["2_1_4"]];
      }
      return [];
    }

    const partialResults = analyzeResults({ answers });
    const { QPrefD, QStrD } = partialResults;

    if (QPrefD === "Feline" && QStrD === "Pets")
      return [section3Questions[3.1], section3Questions[3.2]];
    if (QPrefD === "Feline" && QStrD === "Wild")
      return [section3Questions[3.3], section3Questions[3.4]];
    if (QPrefD === "Canine" && QStrD === "Pets")
      return [section3Questions[3.5], section3Questions[3.6]];
    if (QPrefD === "Canine" && QStrD === "Wild")
      return [section3Questions[3.7], section3Questions[3.8]];
    return [];
  };

  const getCurrentQuestion = (): SurveyQuestion => {
    const questions = getCurrentSectionQuestions();
    const question = questions[currentQuestion];
    logger.log('Current question:', question);
    return question;
  };

  const getTooltipText = (section: number, part: number) => {
    if (section === 1) {
      switch (part) {
        case 1:
          return "Assume both choices are equally viable. All your basic needs are met, and there are no consequences or constraints. Which would you prefer to do?";
        case 2:
          return "Wide gap means you strongly prefer your selection over the other, narrow gap means the two choices are close in preference.";
        case 3:
          return "Thinking back to when you were younger, like in your early 20's, would you have answered differently?";
      }
    } else if (section === 2) {
      switch (part) {
        case 1:
          return "Skill means your ability to perform these actions with competence and completeness. A natural skill is one that does not require extra effort to do well";
        case 2:
          return "Wide gap means you are much more skilled in your selection over the other, narrow gap means the two choices are close in skill.";
        case 3:
          return "Thinking back to when you were younger, like in your early 20's, would you have answered differently?";
      }
    } else {
      switch (part) {
        case 1:
          return "Assume both choices are equally viable. All your basic needs are met, and there are no consequences or constraints. Which would you prefer to do?";
        case 2:
          return "Wide gap means you strongly prefer your selection over the other, narrow gap means the two choices are close in preference.";
        case 3:
          return "Thinking back to when you were younger, like in your early 20's, would you have answered differently?";
      }
    }
    return "";
  };

  // Add useEffect for online/offline detection and sync
  useEffect(() => {
    // Function to handle coming online
    const handleOnline = async () => {
      logger.log('Connection restored, attempting to sync...');
      await syncLocalStorage();
    };

    // Add event listeners
    window.addEventListener('online', handleOnline);

    // Check if we have unsent logs on mount
    const checkPendingLogs = async () => {
      if (navigator.onLine) {
        const logs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
        if (logs.length > 0) {
          logger.log('Found pending logs, attempting to sync...');
          await syncLocalStorage();
        }
      }
    };
    checkPendingLogs();

    // Cleanup
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  // Update DEV_TOOLS to show loading state
  const DEV_TOOLS = import.meta.env.DEV ? (
    <div className="fixed bottom-4 right-4 space-y-2">
      <button
        onClick={() => {
          const logs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
          logger.log('Current logs:', logs);
        }}
        className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
      >
        View Logs
      </button>
      <button
        onClick={() => {
          localStorage.removeItem('surveyLogs');
          logger.log('Logs cleared');
        }}
        className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600 block"
      >
        Clear Logs
      </button>
      <button
        onClick={syncLocalStorage}
        disabled={isSyncing}
        className={`${isSyncing
          ? 'bg-gray-500 cursor-not-allowed'
          : 'bg-green-500 hover:bg-green-600'
          } text-white px-4 py-2 rounded shadow block relative`}
      >
        {isSyncing ? (
          <>
            <span className="opacity-0">Force Sync</span>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full" />
            </div>
          </>
        ) : (
          'Force Sync'
        )}
      </button>
    </div>
  ) : null;

  useEffect(() => {
    try {
      logger.log('Survey mounted with userData:', userData);
      if (!userData?.name) {
        logger.error('Survey received invalid userData:', userData);
      }
    } catch (error) {
      console.error('Error in Survey mount:', error);
    }
  }, [userData]);

  const handleAnswerSelect = (part: 'part1' | 'part2' | 'part3', value: any) => {
    logger.log(`Setting ${part} to:`, value);
    switch (part) {
      case 'part1':
        setPart1(value);
        break;
      case 'part2':
        setPart2(value);
        break;
      case 'part3':
        setPart3(value);
        break;
    }
  };

  const sendQuestionStartedTelemetry = async (questionId: string | number) => {
    try {
      await api.questionStarted({
        sessionId,
        name: userData.name,
        questionId: String(questionId)
      });
    } catch (error) {
      console.error('Failed to send question started telemetry:', error);
    }
  };

  // Update useEffect to send telemetry when question changes
  useEffect(() => {
    const question = getCurrentQuestion();
    if (question) {
      sendQuestionStartedTelemetry(question.id);
    }
  }, [currentSection, currentQuestion]);

  // Add CSS for text stroke
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = ``;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (results) {
    const userDataForResults = {
      name: userData?.name || '',
      sessionId: sessionId,
      accessCode: userData?.accessCode || ''
    };

    return <Results
      results={results}
      onFeedbackSubmit={() => { }}
      userData={userDataForResults}
    />;
  }

  const currentQuestions = getCurrentSectionQuestions();
  const question = getCurrentQuestion();
  const progress =
    ((currentSection - 1) * 100 +
      (currentQuestion + 1) * (100 / currentQuestions.length)) /
    3;

  const getButtonClass = (type: "part1" | "part2" | "part3", value: string) => {
    const baseClass =
      "w-full text-sm font-medium p-4 rounded-2xl transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 whitespace-pre-line text-left break-words ";

    switch (type) {
      case "part1":
        return (
          baseClass +
          (part1 === value
            ? "bg-blue-600 text-white shadow-lg ring-2 ring-blue-400"
            : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-blue-400")
        );
      case "part2":
        return (
          baseClass +
          (part2 === value
            ? "bg-green-600 text-white shadow-lg ring-2 ring-green-400"
            : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-green-400")
        );
      case "part3":
        return (
          baseClass +
          (part3 === value
            ? "bg-purple-600 text-white shadow-lg ring-2 ring-purple-400"
            : "bg-gray-700 text-gray-300 hover:bg-gray-600 focus:ring-purple-400")
        );
    }
  };

  const handleAnswerPart1 = (value: "A" | "B") => {
    logger.log('Part 1 selected:', value);
    const question = getCurrentQuestion();
    question.onSelect?.(value);
    setPart1(value);
    scrollToIfNeeded(part2Ref.current);
  };

  const handleAnswerPart2 = (value: "wide" | "small") => {
    logger.log('Part 2 selected:', value);
    setPart2(value);
    scrollToIfNeeded(part3Ref.current);
  };

  const handleAnswerPart3 = (value: "A" | "B" | "C") => {
    logger.log('Part 3 selected:', value);
    setPart3(value);
    scrollToIfNeeded(nextButtonRef.current);
  };

  const DEBUG_PANEL = config.enableDebugPanel ? (
    <div className="fixed top-4 left-4 bg-gray-800 p-4 rounded shadow text-white text-sm">
      <pre>
        {JSON.stringify(
          {
            section: currentSection,
            question: currentQuestion,
            part1,
            part2,
            part3,
            feedback: questionFeedback,
          },
          null,
          2
        )}
      </pre>
    </div>
  ) : null;



  return (
    <div className="min-h-screen flex items-center justify-center px-1">
      {DEBUG_PANEL}
      {isSendingTelemetry && (
        <div className="fixed top-4 right-4 text-sm text-muted-foreground">
          Saving response...
        </div>
      )}
      <div className="relative w-full max-w-2xl">
        {message && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-20">
            <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 p-6 rounded-lg shadow-lg max-w-md mx-4">
              <p className="mb-4">{message}</p>
              <Button
                onClick={() => setMessage(null)}
                className="w-full bg-yellow-500 hover:bg-yellow-600 text-white"
                variant="default"
              >
                I Understand
              </Button>
            </div>
          </div>
        )}
        <Card className="w-full max-w-2xl mx-0">
          <CardContent className="p-2 sm:p-4">
            <div className="mb-4 relative">
              <Progress value={progress} className="w-full" />
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="bg-white dark:bg-gray-800 px-2 py-0.5 rounded-full text-sm font-medium text-gray-900 dark:text-gray-100 shadow-sm">
                  {Math.round(progress)}%
                </span>
              </div>
            </div>

            {/* Add a container div with padding to ensure proper scroll position */}
            <div className="pt-2">
              {/* Part 1 */}
              <div ref={part1Ref} className="space-y-6 mb-6">
                <div>
                  <Tooltip text={getTooltipText(currentSection, 1)}>
                    <p className="mb-4 text-lg text-foreground dark:text-gray-200">
                      {question.text}
                    </p>
                  </Tooltip>
                  <div className="grid grid-cols-1 gap-3">
                    <Button
                      onClick={() => handleAnswerPart1("A")}
                      variant="outline"
                      disabled={isSubmitting}
                      className={cn(
                        "w-full text-left h-auto whitespace-pre-line p-2 justify-start",
                        part1 === "A" ? "bg-primary text-primary-foreground hover:bg-primary/90" : "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                      )}
                    >
                      <div className="flex items-start w-full">
                        <span className="w-[30px] flex-shrink-0">A)</span>
                        <span className="flex-grow text-left">{question.optionA}</span>
                      </div>
                    </Button>
                    <Button
                      onClick={() => handleAnswerPart1("B")}
                      variant="outline"
                      disabled={isSubmitting}
                      className={cn(
                        "w-full text-left h-auto whitespace-pre-line p-2 justify-start",
                        part1 === "B" ? "bg-primary text-primary-foreground hover:bg-primary/90" : "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                      )}
                    >
                      <div className="flex items-start w-full">
                        <span className="w-[30px] flex-shrink-0">B)</span>
                        <span className="flex-grow text-left">{question.optionB}</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>

              {/* Part 2 */}
              <div ref={part2Ref} className="space-y-4 mb-6">
                <div>
                  <Tooltip text={getTooltipText(currentSection, 2)}>
                    <p className="mb-2 font-semibold text-foreground dark:text-gray-200">
                      How wide is the gap between the two choices above?
                    </p>
                  </Tooltip>
                  <div className="flex gap-2">
                    <Button
                      onClick={() => handleAnswerPart2("wide")}
                      variant={part2 === "wide" ? "default" : "outline"}
                      disabled={isSubmitting}
                      className={cn(
                        "min-w-[100px] w-fit",
                        part2 !== "wide" && "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                      )}
                    >
                      Wide
                    </Button>
                    <Button
                      onClick={() => handleAnswerPart2("small")}
                      variant={part2 === "small" ? "default" : "outline"}
                      disabled={isSubmitting}
                      className={cn(
                        "min-w-[100px] w-fit",
                        part2 !== "small" && "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                      )}
                    >
                      Narrow
                    </Button>
                  </div>
                </div>
              </div>

              {/* Part 3 */}
              <div ref={part3Ref} className="space-y-4 mb-6">
                <div>
                  <Tooltip text={getTooltipText(currentSection, 3)}>
                    <p className="mb-2 font-semibold text-foreground dark:text-gray-200">
                      How has your answer changed over time?
                    </p>
                  </Tooltip>
                  <div className="grid grid-cols-1 gap-2">
                    <Button
                      onClick={() => handleAnswerPart3("A")}
                      variant="outline"
                      disabled={isSubmitting}
                      className={cn(
                        "w-full text-left h-auto px-3 py-2",
                        part3 === "A" ? "bg-primary text-primary-foreground hover:bg-primary/90" : "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                      )}
                    >
                      <span className="block">No change</span>
                    </Button>
                    <Button
                      onClick={() => handleAnswerPart3("B")}
                      variant="outline"
                      disabled={isSubmitting}
                      className={cn(
                        "w-full text-left h-auto px-3 py-2 text-sm",
                        part3 === "B" ? "bg-primary text-primary-foreground hover:bg-primary/90" : "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                      )}
                    >
                      <span className="block">Would have chosen opposite answer</span>
                    </Button>
                    <Button
                      onClick={() => handleAnswerPart3("C")}
                      variant="outline"
                      disabled={isSubmitting}
                      className={cn(
                        "w-full text-left h-auto px-3 py-2 text-sm",
                        part3 === "C" ? "bg-primary text-primary-foreground hover:bg-primary/90" : "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                      )}
                    >
                      <span className="block">Gap between answers has changed</span>
                    </Button>
                  </div>
                </div>
              </div>

              {/* Optional Feedback Section */}
              <div ref={feedbackRef} className="space-y-4 mb-6">
                <div>
                  <p className="mb-2 font-semibold text-foreground dark:text-gray-200">
                    Optional: How easy was this question to understand and answer?
                  </p>
                  <div className="flex gap-2 items-start">
                    <Button
                      onClick={() => {
                        handleQuestionFeedback("easy");
                        setDifficultyFeedback("");
                      }}
                      disabled={isSubmitting}
                      variant={questionFeedback === "easy" ? "default" : "outline"}
                      className={cn(
                        "min-w-[100px] w-fit",
                        questionFeedback !== "easy" && "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                      )}
                    >
                      <span>Easy</span>
                      <span role="img" aria-label="smiley face" className="text-xl ml-2">
                        😊
                      </span>
                    </Button>
                    <div className="flex gap-2 items-start">
                      <Button
                        onClick={() => handleQuestionFeedback("difficult")}
                        disabled={isSubmitting}
                        variant={questionFeedback === "difficult" ? "default" : "outline"}
                        className={cn(
                          "min-w-[100px] w-fit",
                          questionFeedback !== "difficult" && "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                        )}
                      >
                        <span>Difficult</span>
                        <span role="img" aria-label="confused face" className="text-xl ml-2">
                          😕
                        </span>
                      </Button>
                      {questionFeedback === "difficult" && (
                        <div className="flex-1 ml-3">
                          <Input
                            type="text"
                            value={difficultyFeedback}
                            onChange={(e) => setDifficultyFeedback(e.target.value)}
                            placeholder="How so?"
                            disabled={isSubmitting}
                            className="w-full dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <Button
                ref={nextButtonRef}
                onClick={handleNext}
                disabled={!part1 || !part2 || !part3 || isSubmitting}
                className={cn(
                  "w-full transition-all duration-200",
                  (!part1 || !part2 || !part3 || isSubmitting)
                    ? "bg-blue-500/60 hover:bg-blue-500/70 disabled:bg-blue-500/40"
                    : "bg-blue-500 hover:bg-blue-600"
                )}
                variant="default"
              >
                {isSubmitting ? (
                  <div className="flex items-center justify-center gap-2">
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                    Submitting...
                  </div>
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      {DEV_TOOLS}
    </div>
  );
};

export default Survey;