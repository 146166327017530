import React, { useState } from 'react';
import { UserData } from '../types';
import { scrollToTop } from '../utils/scroll';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import NdaPopup from './NdaPopup';
import { api } from '../utils/api';

interface WelcomePageProps {
    userData: UserData;
    onContinue: () => void;
    sessionId: string;
}

const WelcomePage: React.FC<WelcomePageProps> = ({ userData, onContinue, sessionId }) => {
    const [showNda, setShowNda] = useState(false);

    const handleContinue = () => {
        setShowNda(true);
    };

    const handleNdaAccept = async () => {
        try {
            await api.ndaAccepted({
                sessionId,
                name: userData.name
            });

            scrollToTop();
            setTimeout(() => {
                onContinue();
            }, 75);
        } catch (error) {
            console.error('Error logging NDA acceptance:', error);
            // Continue anyway to not block the user
            scrollToTop();
            setTimeout(() => {
                onContinue();
            }, 75);
        }
    };

    const handleNdaDecline = () => {
        setShowNda(false);
    };

    return (
        <div className="min-h-screen flex items-center justify-center px-1">
            {showNda && (
                <NdaPopup
                    onAccept={handleNdaAccept}
                    onClose={handleNdaDecline}
                />
            )}

            <Card className="w-full max-w-4xl mx-0">
                <CardHeader className="px-2 sm:px-4 pt-4">
                    <CardTitle className="text-3xl font-bold text-primary">
                        Welcome to the Four Coins Data Validation Initiative
                    </CardTitle>
                </CardHeader>
                <CardContent className="px-2 sm:px-4 space-y-6">
                    <p className="text-lg text-foreground dark:text-gray-200">
                        Thank you for participating in our survey to validate the Four Coins Model. Your input is invaluable in helping us better understand recurring data patterns behind human cognition and thinking styles.
                    </p>

                    <Card className="dark:bg-gray-900"> {/*Card className="dark:bg-gray-700">*/}
                        <CardHeader>
                            <CardTitle className="text-2xl font-semibold text-foreground dark:text-gray-200">
                                Survey
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4 text-foreground dark:text-gray-200">
                            <p>
                                Our private, limited audience survey takes approximately 15-20 minutes to complete and consists of a series of questions designed to assess your thinking style. Your responses will be kept confidential and used solely for data model validation purposes. (Your data will not leave our secure, DigitalOcean Cloud Provider Servers).                            </p>
                            <p>
                                The survey consists of questions that will examine your "thinking style" including your natural strengths and blind-spots as well as your thinking preferences.
                            </p>
                        </CardContent>
                    </Card>

                    <Card className="dark:bg-gray-900"> {/*Card className="dark:bg-gray-700">*/}
                        <CardHeader>
                            <CardTitle className="text-2xl font-semibold text-foreground dark:text-gray-200">
                                Feedback
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4 text-foreground dark:text-gray-200">
                            <p>
                                You will have the opportunity to provide feedback about the difficulty or ease of answering questions. You can provide feedback on questions during the survey and again at the end. By design, some questions will feel unnatural given not all questions resonate with everyone. This acts as another point of validation to ensure an accurate assessment.
                            </p>
                            <p>
                                Your individualized survey results will be shown after you complete the survey. Do you think the survey accurately captured your thinking style? Or did it miss the mark? Tell us! We appreciate any and all feedback you have about your results, especially what you perceive is a miss.
                            </p>
                        </CardContent>
                    </Card>

                    <Card className=" dark:bg-blue-900">
                        <CardHeader>
                            <CardTitle className="text-2xl font-semibold text-blue-800 dark:text-blue-100">
                                Navigation Tip and Contact Info
                            </CardTitle>
                        </CardHeader>
                        <CardContent>

                            <p className="mb-4 text-foreground dark:text-gray-200">
                                The back button in your browser will send you back to the beginning of the survey! We do not yet have a way to go back to previous questions in the survey, so please be careful when answering.
                            </p>

                            <p className="mb-4 text-foreground dark:text-gray-200">
                                Technical Issues? Or if you are curious about your results and want to speak to Ben, the architect of the model, please reach out: <a href="mailto:quadrasolutionsllc@gmail.com">quadrasolutionsllc@gmail.com</a>.
                            </p>
                            <p className="mb-4 text-foreground dark:text-gray-200">
                                Contact or Follow us on our LinkedIn page{' '}
                                <a
                                    href="https://www.linkedin.com/company/quadra-solutions-llc"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 underline"
                                >
                                    HERE
                                </a>
                            </p>
                        </CardContent>
                    </Card>

                    <div className="space-y-2 text-foreground dark:text-gray-200">
                        <p className="text-lg">
                            We appreciate your willingness to participate,
                        </p>
                        <p className="font-semibold">
                            The Four Coins Team
                        </p>
                    </div>
                </CardContent>
                <CardFooter className="flex justify-center px-2 sm:px-4 pb-4">
                    <Button
                        onClick={handleContinue}
                        size="lg"
                        className="w-full sm:w-auto"
                    >
                        Begin Survey
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default WelcomePage;

