import { SurveyData, AnalysisResult, SurveyAnswer } from "../types";

function calculateScore(
  answer: SurveyAnswer,
  isSection1: boolean,
  isSection3: boolean
): number {
  const score = answer.part2 === "wide" ? 3 : 1;

  if (isSection1 || isSection3) {
    if (answer.part3 === "A") {
      return score;
    } else if (answer.part3 === "B") {
      if (answer.part2 === "wide") {
        return 0;
      }
      return -score;
    } else {
      // part3 === 'C'
      return answer.part2 === "wide" ? 1 : 3;
    }
  } else {
    //     // Section 2 and 2_1 logic
    if (answer.part3 === "A") {
      return score;
    } else if (answer.part3 === "B") {
      return answer.part2 === "wide" ? -1 : -score;
    } else {
      // part3 === 'C'
      return answer.part2 === "wide" ? 1 : 3;
    }
  }
  return 0;
}

function hasHighConfidenceSection3(answers: SurveyAnswer[]): boolean {
  // Filter for only Section 3 answers (those with IDs >= 3)
  const section3Answers = answers.filter((answer) => Number(answer.questionId) >= 3);

  // Check if we have at least 2 answers for Section 3
  if (section3Answers.length < 2) return false;

  // Get first two answers with their adjusted scores
  const firstAdjustedScore = calculateScore(section3Answers[0], false, true);
  const secondAdjustedScore = calculateScore(section3Answers[1], false, true);

  return (
    // Check if signs match (both positive or both negative)
    Math.sign(firstAdjustedScore) === Math.sign(secondAdjustedScore) &&
    // Check if both answers have wide gaps and no change
    (section3Answers[0].part3 === "A" || section3Answers[0].part3 === "C") &&
    (section3Answers[1].part3 === "A" || section3Answers[1].part3 === "C")
  );
}

export function analyzeResults(data: SurveyData): AnalysisResult {
  // Get answers for each section
  const section1 = data.answers.slice(0, 4); // Questions 1,2,5,6
  const section2 = data.answers.slice(4, 12); // Questions 9,10,13,14,17,18,21,22
  const tieBreaker = data.answers.find((a) =>
    [2.11, 2.12, 2.13, 2.14].includes(Number(a.questionId))
  ); // Tie-breaker
  const section3 = data.answers.slice(12); // Two branching questions

  // Add debugging
  console.log("All answers:", data.answers);
  console.log("Section 3 answers:", section3);

  let s1dogs = 0,
    s1cats = 0,
    s1wolves = 0,
    s1lions = 0;
  let s2dogs = 0,
    s2cats = 0,
    s2wolves = 0,
    s2lions = 0;
  let s3kangaroo = 0,
    s3giraffe = 0,
    s3horses = 0,
    s3unicorns = 0;

  // Calculate scores for section 1
  section1.forEach((answer, index) => {
    const score = calculateScore(answer, true, false);
    switch (index) {
      case 0: // Question 1
        if (answer.part1 === "A") {
          s1cats += score; // Increment score for Cats
        } else {
          s1dogs += score; // Increment score for Dogs
        }
        break;
      case 1: // Question 2
        if (answer.part1 === "A") {
          s1wolves += score; // Increment score for Wolves
        } else {
          s1lions += score; // Increment score for Lions
        }
        break;
      case 2: // Question 3
        if (answer.part1 === "A") {
          s1cats += score; // Increment score for Cats
        } else {
          s1dogs += score; // Increment score for Dogs
        }
        break;
      case 3: // Question 4
        if (answer.part1 === "A") {
          s1lions += score; // Increment score for Lions
        } else {
          s1wolves += score; // Increment score for Wolves
        }
        break;
    }
  });

  // Calculate Section 2 scores (QStrD and MPolDe)
  section2.forEach((answer, index) => {
    const score = calculateScore(answer, false, false);
    switch (index) {
      case 0: // Question 9
      case 2: // Question 13
      case 4: // Question 17
      case 6: // Question 21
        if (answer.part1 === "A") {
          s2cats += score;
          s2lions -= score;
        } else {
          s2cats -= score;
          s2lions += score;
        }
        break;
      case 1: // Question 10
      case 3: // Question 14
      case 5: // Question 18
      case 7: // Question 22
        if (answer.part1 === "A") {
          s2dogs += score;
          s2wolves -= score;
        } else {
          s2dogs -= score;
          s2wolves += score;
        }
        break;
    }
  });

  // Process tie-breaker if it exists
  if (tieBreaker) {
    const tieBreakerAnswer = tieBreaker.part1;
    const score = calculateScore(tieBreaker, false, false); // Add this line
    switch (tieBreaker.questionId) {
      case 2.11: // Feline + Pets
        if (tieBreakerAnswer === "A") s2cats += score;
        else s2dogs += score;
        break;
      case 2.12: // Feline + Wild
        if (tieBreakerAnswer === "A") s2lions += score;
        else s2wolves += score;
        break;
      case 2.13: // Canine + Pets
        if (tieBreakerAnswer === "A") s2dogs += score;
        else s2cats += score;
        break;
      case 2.14: // Canine + Wild
        if (tieBreakerAnswer === "A") s2wolves += score;
        else s2lions += score;
        break;
    }
  }

  // Calculate initial values
  const s1feline = s1cats + s1lions;
  const s1canine = s1dogs + s1wolves;
  const QPrefD = s1feline === s1canine ? "Unknown" : s1feline > s1canine ? "Feline" : "Canine";

  const s2pet = s2cats + s2dogs;
  const s2wild = s2wolves + s2lions;
  const QStrD = s2pet > s2wild ? "Pets" : "Wild";

  const MPolDe =
    Math.abs(s2dogs) + Math.abs(s2wolves) > Math.abs(s2cats) + Math.abs(s2lions)
      ? "Canine"
      : "Feline";

  // Calculate Section 3 scores
  section3.forEach((answer) => {
    const score = calculateScore(answer, false, true);
    const questionId = answer.questionId;

    // Questions 3.1, 3.2, 3.7, 3.8 are Giraffes vs Horses
    if ([3.1, 3.2, 3.7, 3.8].includes(Number(questionId))) {
      if (answer.part1 === "A") {
        // Chose Giraffes
        s3giraffe += score; // Full score to primary choice
        s3kangaroo += score / 2; // Half score to related
        s3horses -= score; // Negative to opposite
        s3unicorns -= score / 2; // Half negative to related opposite
      } else {
        // Chose Horses
        s3giraffe -= score;
        s3kangaroo -= score / 2;
        s3horses += score;
        s3unicorns += score / 2;
      }
    }
    // Questions 3.3, 3.4, 3.5, 3.6 are Kangaroos vs Unicorns
    else if ([3.3, 3.4, 3.5, 3.6].includes(Number(questionId))) {
      if (answer.part1 === "A") {
        // Chose Kangaroos
        s3kangaroo += score; // Full score to primary choice
        s3giraffe += score / 2; // Half score to related
        s3unicorns -= score; // Negative to opposite
        s3horses -= score / 2; // Half negative to related opposite
      } else {
        // Chose Unicorns
        s3kangaroo -= score;
        s3giraffe -= score / 2;
        s3unicorns += score;
        s3horses += score / 2;
      }
    }
  });

  // Calculate remaining values
  const s3doofs = s3giraffe + s3kangaroo;
  const s3equine = s3horses + s3unicorns;
  const QPrefL = s3doofs === s3equine ? "Unknown" : s3doofs > s3equine ? "Doofs" : "Equine";

  // Calculate confidence values and directional accuracy
  const PrefPairDeConVal = Math.max(Math.abs(s1feline), Math.abs(s1canine));
  const PrefPairLeConVal = Math.max(Math.abs(s3doofs), Math.abs(s3equine));

  const PrefPairDeConDir =
    Math.sign(s1cats) === Math.sign(s1lions) &&
    Math.sign(s1dogs) === Math.sign(s1wolves) &&
    Math.sign(s1cats) != Math.sign(s1dogs) &&
    Math.sign(s1lions) != Math.sign(s1wolves)
    ;

  const PrefPairLeConDir = hasHighConfidenceSection3(section3);

  // Calculate strength pairs
  const StrPairEtlo = s2pet > s2wild ? "Pets" : "Wild";
  const StrPairSein = QPrefL === "Doofs" ? "Brown" : "Fancy";

  // Calculate strength pair confidence
  const StrPairEtloConVar = Math.abs(s2pet - s2wild) > 3 ? "High" : "Low";
  const StrPairSeinConVar = hasHighConfidenceSection3(section3)
    ? "High"
    : "Low";

  // Determine Q, QStrL, and MPolLe based on branching logic
  let Q = "Unknown";
  let QStrL = "Unknown";
  let MPolLe = "Unknown";

  const decPrefConfidence = PrefPairLeConDir && PrefPairDeConVal >= 3;

  //if (PrefPairLeConDir && PrefPairDeConVal >= 3) { need better confidence logic. Need an answer either way.
  if (QPrefD === "Feline" && QStrD === "Pets" && QPrefL === "Equine") {
    Q = "Alfred";
    QStrL = "Brown";
    MPolLe = MPolDe === "Feline" ? "Doofs" : "Equine";
  } else if (QPrefD === "Feline" && QStrD === "Wild" && QPrefL === "Equine") {
    Q = "Alfred";
    QStrL = "Fancy";
    MPolLe = MPolDe === "Feline" ? "Doofs" : "Equine";
  } else if (QPrefD === "Feline" && QStrD === "Wild" && QPrefL === "Doofs") {
    Q = "Boris";
    QStrL = "Brown";
    MPolLe = MPolDe === "Feline" ? "Equine" : "Doofs";
  } else if (QPrefD === "Feline" && QStrD === "Pets" && QPrefL === "Doofs") {
    Q = "Boris";
    QStrL = "Fancy";
    MPolLe = MPolDe === "Feline" ? "Equine" : "Doofs";
  } else if (QPrefD === "Canine" && QStrD === "Pets" && QPrefL === "Doofs") {
    Q = "George";
    QStrL = "Brown";
    MPolLe = MPolDe === "Feline" ? "Doofs" : "Equine";
  } else if (QPrefD === "Canine" && QStrD === "Wild" && QPrefL === "Doofs") {
    Q = "George";
    QStrL = "Fancy";
    MPolLe = MPolDe === "Feline" ? "Doofs" : "Equine";
  } else if (QPrefD === "Canine" && QStrD === "Pets" && QPrefL === "Equine") {
    Q = "Daniel";
    QStrL = "Fancy";
    MPolLe = MPolDe === "Feline" ? "Equine" : "Doofs";
  } else if (QPrefD === "Canine" && QStrD === "Wild" && QPrefL === "Equine") {
    Q = "Daniel";
    QStrL = "Brown";
    MPolLe = MPolDe === "Feline" ? "Equine" : "Doofs";
  }

  //} end of confidence check if statement

  // Calculate type
  const type = calculateType(QPrefD, QPrefL, QStrD, QStrL, MPolDe, MPolLe);

  return {
    QPrefD,
    QPrefL,
    QStrD,
    QStrL,
    MPolDe,
    MPolLe,
    Q,
    PrefPairDe: QPrefD,
    PrefPairLe: QPrefL,
    PrefPairDeConVal,
    PrefPairLeConVal,
    PrefPairDeConDir,
    PrefPairLeConDir,
    StrPairEtlo,
    StrPairSein,
    StrPairEtloConVar,
    StrPairSeinConVar,
    decPrefConfidence,
    s1dogs,
    s1cats,
    s1wolves,
    s1lions,
    s2dogs,
    s2cats,
    s2wolves,
    s2lions,
    s3kangaroo,
    s3giraffe,
    s3horses,
    s3unicorns,
    type,
  };
}

function calculateType(
  QPrefD: string,
  QPrefL: string,
  QStrD: string,
  QStrL: string,
  MPolDe: string,
  MPolLe: string
): number {
  const key =
    ((QPrefD === "Canine" ? 1 : 0) << 5) |
    ((QPrefL === "Equine" ? 1 : 0) << 4) |
    ((QStrD === "Pets" ? 1 : 0) << 3) |
    ((QStrL === "Fancy" ? 1 : 0) << 2) |
    ((MPolDe === "Canine" ? 1 : 0) << 1) |
    (MPolLe === "Doofs" ? 1 : 0);
  //100110
  const typeLookup: Record<number, number> = {
    0b111111: 1, // Daniel
    0b111100: 2,
    0b110000: 3,
    0b110011: 4,
    0b011010: 5, // Alfred
    0b011001: 6,
    0b010101: 7,
    0b010110: 8,
    0b001111: 9, // Boris
    0b001100: 10,
    0b000000: 11,
    0b000011: 12,
    0b101010: 13, // George
    0b101001: 14,
    0b100101: 15,
    0b100110: 16,
  };

  return typeLookup[key] || 0;
}
