import { getConfig } from '../config/environments';
import { logger } from './logger';

// Base telemetry interface with common fields
interface BaseTelemetryData {
    sessionId: string;
    timestamp?: string;
}

// Answer submitted telemetry
interface AnswerSubmittedData extends BaseTelemetryData {
    userData: {
        name: string;
        accessCode?: string;
    };
    message_name: 'answer_submitted';
    questionId: string;
    part1: 'A' | 'B';
    part2: 'wide' | 'small';
    part3: 'A' | 'B' | 'C';
    questionFeedback: 'easy' | 'difficult' | null;
    difficultyFeedback: string;
    metadata?: {
        browser?: string;
        platform?: string;
        sessionId?: string;
        surveyStatus: 'in_progress' | 'completed' | 'abandoned';
        questionIndex?: number;
        totalQuestions?: number;
        buildNumber?: string;
        buildDate?: string;
        environment?: string;
    };
}

// Session started telemetry
interface SessionStartedData extends BaseTelemetryData {
    browserMetadata: {
        userAgent: string;
        language: string;
        platform: string;
        screenResolution?: string;
        timeZone?: string;
    };
    buildNumber: string;
    buildDate: string;
    environment: string;
}

// Survey started telemetry
interface SurveyStartedData extends BaseTelemetryData {
    name: string;
    email: string;
    accessCode: string;
    started: boolean;
    reason: string;
    startTime: string;
}

// Question started telemetry
interface QuestionStartedData extends BaseTelemetryData {
    name: string;
    questionId: string;
}

// Results feedback telemetry
interface ResultsFeedbackData extends BaseTelemetryData {
    name: string;
    highlightedText: string;
    comment: string;
    feedbackType: 'agree' | 'disagree';
}

// Survey ended telemetry
interface SurveyEndedData extends BaseTelemetryData {
    name: string;
}

// NDA acceptance telemetry
interface NdaAcceptedData extends BaseTelemetryData {
    name: string;
}

// Results telemetry
interface ResultsData extends BaseTelemetryData {
    name: string;
    type: string;
    qpref_d: string;
    qpref_l: string;
    qstr_d: string;
    qstr_l: string;
    mpol_de: string;
    mpol_le: string;
    q: string;
    s1_dogs: number;
    s1_cats: number;
    s1_wolves: number;
    s1_lions: number;
    s2_dogs: number;
    s2_cats: number;
    s2_wolves: number;
    s2_lions: number;
    s3_kangaroo: number;
    s3_giraffe: number;
    s3_horses: number;
    s3_unicorns: number;
    pref_pair_de: string;
    pref_pair_le: string;
    pref_pair_de_con_val: number;
    pref_pair_le_con_val: number;
    pref_pair_de_con_dir: boolean;
    pref_pair_le_con_dir: boolean;
    str_pair_etlo: string;
    str_pair_sein: string;
    str_pair_etlo_con_var: string;
    str_pair_sein_con_var: string;
}

async function makeApiCall<T>(endpoint: string, data: unknown): Promise<T> {
    const config = getConfig();
    // Remove /api prefix if it exists since it's already in the base URL
    const cleanEndpoint = endpoint.startsWith('/api/') ? endpoint.substring(4) : endpoint;

    logger.log('Making API call to:', cleanEndpoint, 'with config:', {
        apiBaseUrl: config.apiBaseUrl,
        environment: config.environment,
        basePath: config.basePath
    });

    // For staging, use /staging/api/endpoint
    // For production, use /api/endpoint
    const url = config.environment === 'staging'
        ? `${config.apiBaseUrl}/staging/api/${cleanEndpoint}`
        : `${config.apiBaseUrl}/api/${cleanEndpoint}`;

    logger.log('Final URL:', url);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(`API Error: ${response.status} - ${JSON.stringify(errorData)}`);
    }

    return response.json();
}

export const api = {
    // Answer submitted
    sendTelemetry: (data: AnswerSubmittedData) =>
        makeApiCall('telemetry', data),

    // Session started
    sessionStarted: (data: SessionStartedData) =>
        makeApiCall('telemetry/session-started', data),

    // Survey started
    surveyStarted: (data: SurveyStartedData) =>
        makeApiCall('telemetry/survey-entered', data),

    // Question started
    questionStarted: (data: QuestionStartedData) =>
        makeApiCall('telemetry/question-started', data),

    // Results feedback
    resultsFeedback: (data: ResultsFeedbackData) =>
        makeApiCall('telemetry/results-feedback', data),

    // Survey ended
    surveyEnded: (data: SurveyEndedData) =>
        makeApiCall('telemetry/survey-ended', data),

    // NDA accepted
    ndaAccepted: (data: NdaAcceptedData) =>
        makeApiCall('telemetry/nda-accepted', data),

    // Results
    results: (data: ResultsData) =>
        makeApiCall('telemetry/results', data),

    // Version check
    getVersion: async () => {
        const config = getConfig();
        const response = await fetch(`${config.apiBaseUrl}/api/version`);
        if (!response.ok) {
            throw new Error(`Version check failed: ${response.status}`);
        }
        return response.json();
    }
}; 