import React, { useState, useCallback, useEffect } from 'react';
import { AnalysisResult } from '../types';
import { getTypeDescription } from '../data/typeDescriptionDetails';
import FeedbackPopup from './FeedbackPopup';
import ThankYouPage from './ThankYouPage';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { api } from '../utils/api';

interface ResultsProps {
  results: AnalysisResult | null;
  onFeedbackSubmit: (feedback: string) => void;
  userData: {
    name: string;
    sessionId?: string;
    accessCode?: string;
  };
}

const Results: React.FC<ResultsProps> = ({ results, onFeedbackSubmit, userData = { name: '', sessionId: '', accessCode: '' } }) => {
  const [showInstructions, setShowInstructions] = useState(true);
  const [selectedText, setSelectedText] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);

  useEffect(() => {
    const sendResultsTelemetry = async () => {
      if (!results || !userData.name || !userData.sessionId) return;

      try {
        await api.results({
          sessionId: userData.sessionId,
          name: userData.name,
          type: results.type,
          qpref_d: String(results.QPrefD),
          qpref_l: String(results.QPrefL),
          qstr_d: String(results.QStrD),
          qstr_l: String(results.QStrL),
          mpol_de: String(results.MPolDe),
          mpol_le: String(results.MPolLe),
          q: String(results.Q),
          s1_dogs: Number(results.s1dogs),
          s1_cats: Number(results.s1cats),
          s1_wolves: Number(results.s1wolves),
          s1_lions: Number(results.s1lions),
          s2_dogs: Number(results.s2dogs),
          s2_cats: Number(results.s2cats),
          s2_wolves: Number(results.s2wolves),
          s2_lions: Number(results.s2lions),
          s3_kangaroo: Number(results.s3kangaroo),
          s3_giraffe: Number(results.s3giraffe),
          s3_horses: Number(results.s3horses),
          s3_unicorns: Number(results.s3unicorns),
          pref_pair_de: String(results.PrefPairDe),
          pref_pair_le: String(results.PrefPairLe),
          pref_pair_de_con_val: Number(results.PrefPairDeConVal),
          pref_pair_le_con_val: Number(results.PrefPairLeConVal),
          pref_pair_de_con_dir: Boolean(results.PrefPairDeConDir),
          pref_pair_le_con_dir: Boolean(results.PrefPairLeConDir),
          str_pair_etlo: String(results.StrPairEtlo),
          str_pair_sein: String(results.StrPairSein),
          str_pair_etlo_con_var: String(results.StrPairEtloConVar),
          str_pair_sein_con_var: String(results.StrPairSeinConVar)
        });
      } catch (error) {
        console.error('Failed to send results telemetry:', error);
      }
    };

    sendResultsTelemetry();
  }, [results, userData]);

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const target = event.target as HTMLSpanElement;
    if (target.className.includes('feedback-sentence')) {
      setSelectedText(target.textContent?.trim() || '');
      setShowPopup(true);
    }
  };

  //rendering results
  const renderSentences = (text: string | undefined) => {
    if (!text) return null;

    return text.split(/\n\s*\n/).map((paragraph, pIndex) => (
      <div key={`p-${pIndex}`} className="mb-4">
        {paragraph.split(/(?<=\.)\s+/).map((sentence, sIndex) => (
          <span
            key={`${pIndex}-${sIndex}`}
            className="feedback-sentence cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 px-1 rounded"
            onClick={handleClick}
          >
            {sentence.trim()}{sIndex < paragraph.split(/(?<=\.)\s+/).length - 1 ? ' ' : ''}
          </span>
        ))}
      </div>
    ));
  };

  const handleClosePopup = useCallback(() => {
    // Clear the selection when closing the popup
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
    setShowPopup(false);
  }, []);

  const handleFeedbackSubmit = async (feedback: {
    selectedText: string;
    comments: string;
    agree: boolean;
    disagree: boolean;
  }) => {
    console.log('Attempting to send feedback telemetry:', {
      sessionId: userData.sessionId,
      name: userData.name || '',
      feedback
    });

    try {
      await api.resultsFeedback({
        sessionId: userData.sessionId,
        name: userData.name || '',
        highlightedText: feedback.selectedText,
        comment: feedback.comments,
        feedbackType: feedback.agree ? 'agree' : 'disagree'
      });
    } catch (error) {
      console.error('Failed to send results feedback telemetry:', error);
    }

    onFeedbackSubmit(JSON.stringify(feedback));
    setShowPopup(false);
  };

  const handleSurveyEnd = async () => {
    if (!userData.name || !userData.sessionId) {
      console.error('Missing required user data for survey end');
      return;
    }

    console.log('Attempting to send survey ended telemetry:', {
      sessionId: userData.sessionId,
      name: userData.name
    });

    try {
      await api.surveyEnded({
        sessionId: userData.sessionId,
        name: userData.name
      });
      setSurveyCompleted(true);
    } catch (error) {
      console.error('Failed to send survey ended telemetry:', error);
    }
  };

  if (surveyCompleted) {
    return <ThankYouPage />;
  }

  if (!results) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-4xl text-center">
        <Card>
          <CardContent>
            <p className="text-foreground">No results available</p>
          </CardContent>
        </Card>
      </div>
    );
  }

  const detailedDescription = getTypeDescription(results.type);

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      {showInstructions && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <Card className="max-w-md w-full p-6 bg-white dark:bg-gray-800">
            <h3 className="text-lg font-semibold mb-4">Welcome to Your Results</h3>
            <p className="mb-6">
              The following are your survey results. Please click (or tap) any sentences
              which you have strong positive or negative feedback on.
            </p>
            <Button
              className="w-full"
              onClick={() => setShowInstructions(false)}
            >
              OK
            </Button>
          </Card>
        </div>
      )}

      {detailedDescription && (
        <Card className="mb-8">
          <CardHeader>
            <CardTitle className="text-2xl">{detailedDescription.title}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="prose max-w-none dark:prose-invert mb-6">
              {detailedDescription?.detailedDescription && renderSentences(detailedDescription.detailedDescription)}
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">Growth Areas</h3>
              <ul className="list-disc pl-5 space-y-2">
                {detailedDescription?.characteristics?.map((char, index) => (
                  <li key={index} className="text-foreground">
                    {renderSentences(char)}
                  </li>
                ))}
              </ul>
            </div>

            <Card className="bg-primary/5">
              <CardHeader>
                <CardTitle className="text-primary">Challenging Growth Areas</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="list-disc pl-5 space-y-2">
                  {detailedDescription?.challenges?.map((challenge, index) => (
                    <li key={index} className="text-foreground">
                      {renderSentences(challenge)}
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      )}

      {
        //commenting out intermediate values. Delete later. 
        /* <Card> 
          <CardHeader>
            <CardTitle>Your Animal Profile Results</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6">
              <div>
                <h3 className="text-xl font-semibold mb-2">Final Results</h3>
                <div className="grid grid-cols-2 gap-2">
                  <p><strong>Type:</strong> {type}</p>
                  <p><strong>QPrefD:</strong> {QPrefD}</p>
                  <p><strong>QPrefL:</strong> {QPrefL}</p>
                  <p><strong>QStrD:</strong> {QStrD}</p>
                  <p><strong>QStrL:</strong> {QStrL}</p>
                  <p><strong>MPolDe:</strong> {MPolDe}</p>
                  <p><strong>MPolLe:</strong> {MPolLe}</p>
                  <p><strong>Q:</strong> {Q}</p>
                </div>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Intermediate Values</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <h4 className="text-lg font-semibold mt-2">Section 1</h4>
                    <p><strong>s1Dogs:</strong> {s1dogs}</p>
                    <p><strong>s1Cats:</strong> {s1cats}</p>
                    <p><strong>s1Wolves:</strong> {s1wolves}</p>
                    <p><strong>s1Lions:</strong> {s1lions}</p>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold mt-2">Section 2</h4>
                    <p><strong>s2Dogs:</strong> {s2dogs}</p>
                    <p><strong>s2Cats:</strong> {s2cats}</p>
                    <p><strong>s2Wolves:</strong> {s2wolves}</p>
                    <p><strong>s2Lions:</strong> {s2lions}</p>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold mt-2">Section 3</h4>
                    <p><strong>s3Kangaroo:</strong> {s3kangaroo}</p>
                    <p><strong>s3Giraffe:</strong> {s3giraffe}</p>
                    <p><strong>s3Horses:</strong> {s3horses}</p>
                    <p><strong>s3Unicorns:</strong> {s3unicorns}</p>
                  </div>
                </div>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Preference Pairs</h3>
                <p><strong>PrefPairDe:</strong> {PrefPairDe}</p>
                <p><strong>PrefPairLe:</strong> {PrefPairLe}</p>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Confidence Values</h3>
                <p><strong>PrefPairDeConVal:</strong> {PrefPairDeConVal}</p>
                <p><strong>PrefPairLeConVal:</strong> {PrefPairLeConVal}</p>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Directional Accuracy</h3>
                <p><strong>PrefPairDeConDir:</strong> {PrefPairDeConDir ? 'True' : 'False'}</p>
                <p><strong>PrefPairLeConDir:</strong> {PrefPairLeConDir ? 'True' : 'False'}</p>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Strength Pairs</h3>
                <p><strong>StrPairEtlo:</strong> {StrPairEtlo}</p>
                <p><strong>StrPairSein:</strong> {StrPairSein}</p>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Strength Pair Confidence</h3>
                <p><strong>StrPairEtloConVar:</strong> {StrPairEtloConVar}</p>
                <p><strong>StrPairSeinConVar:</strong> {StrPairSeinConVar}</p>
              </div>
            </div>
          </CardContent>
        </Card> */
      }

      <div className="mt-12 mb-16 text-center">
        <Button onClick={handleSurveyEnd} size="lg">
          End Survey
        </Button>
      </div>

      {showPopup && (
        <FeedbackPopup
          selectedText={selectedText}
          onClose={handleClosePopup}
          onSubmit={handleFeedbackSubmit}
        />
      )}
    </div>
  );
};

export default Results;