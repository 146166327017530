import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "./ui/card";
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";

interface FeedbackPopupProps {
  selectedText: string;
  onClose: () => void;
  onSubmit: (feedback: {
    selectedText: string;
    comments: string;
    agree: boolean;
    disagree: boolean;
  }) => void;
}

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({
  selectedText,
  onClose,
  onSubmit
}) => {
  const [comments, setComments] = useState('');
  const [agree, setAgree] = useState(false);
  const [disagree, setDisagree] = useState(false);

  const handleSubmit = () => {
    onSubmit({
      selectedText,
      comments,
      agree,
      disagree
    });
  };

  const handleAgree = () => {
    setAgree(true);
    setDisagree(false);
  };

  const handleDisagree = () => {
    setDisagree(true);
    setAgree(false);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <Card
        className="w-full max-w-md bg-background"
        onClick={e => e.stopPropagation()}
      >
        <CardHeader className="relative">
          <CardTitle className="text-lg">Feedback</CardTitle>
          <Button
            onClick={onClose}
            variant="ghost"
            size="sm"
            className="absolute top-2 right-2 h-8 w-8 p-0"
          >
            ✕
          </Button>
        </CardHeader>
        <CardContent className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-2">
              Selected Text
            </label>
            <div className="p-3 bg-muted rounded-md text-sm border">
              "{selectedText}"
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">
              Additional Comments
            </label>
            <Textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              rows={3}
              className="w-full"
            />
          </div>
          <div className="flex gap-2">
            <Button
              onClick={handleAgree}
              variant={agree ? "default" : "outline"}
              className="flex-1"
            >
              I agree
            </Button>
            <Button
              onClick={handleDisagree}
              variant={disagree ? "default" : "outline"}
              className="flex-1"
            >
              I disagree
            </Button>
          </div>
        </CardContent>
        <CardFooter>
          <Button
            onClick={handleSubmit}
            className="w-full"
            disabled={!agree && !disagree}
          >
            Submit Feedback
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default FeedbackPopup; 