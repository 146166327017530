import React from 'react';

const ThankYouPage: React.FC = () => {
  return (
    <div className="max-w-2xl mx-auto mt-16 p-8 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-center mb-8 text-blue-600">
        Thank You
      </h1>
      <p className="text-lg text-gray-700 leading-relaxed text-center mb-6">
        Thank you for taking the survey. We appreciate your help on our journey to foster meaningful connections in a rapidly evolving world.
      </p>
      <p className="text-lg text-gray-700 leading-relaxed text-center">
        Your responses have been logged and you may hear from the Quadra team for additional feedback.
      </p>
      <br />
      <p className="text-lg text-gray-700 leading-relaxed text-center">
        You can now close this page.
      </p>
    </div>
  );
};

export default ThankYouPage; 