const isDev = import.meta.env.DEV;
const isLoggingEnabled = import.meta.env.VITE_ENABLE_LOGGING === 'true' || isDev;
const env = import.meta.env.VITE_APP_ENV || (isDev ? 'development' : 'production');

type LogArgs = unknown[];

export const logger = {
    log: (...args: LogArgs) => {
        try {
            if (isLoggingEnabled) {
                console.log(`[${env.toUpperCase()}]`, ...args);
            }
        } catch (error) {
            // Fallback direct console log if something goes wrong
            console.log('[FALLBACK]', ...args);
        }
    },
    error: (...args: LogArgs) => {
        try {
            if (isLoggingEnabled) {
                console.error(`[${env.toUpperCase()}]`, ...args);
            }
        } catch (error) {
            console.error('[FALLBACK ERROR]', ...args);
        }
    },
    warn: (...args: LogArgs) => {
        if (isLoggingEnabled) {
            console.warn(`[${env.toUpperCase()}]`, ...args);
        }
    },
    debug: (...args: LogArgs) => {
        if (isLoggingEnabled) {
            console.debug(`[${env.toUpperCase()}]`, ...args);
        }
    }
};

// Add an initialization check
try {
    logger.log('Logger initialized with config:', {
        isDev,
        isLoggingEnabled,
        env,
        VITE_APP_ENV: import.meta.env.VITE_APP_ENV,
        VITE_ENABLE_LOGGING: import.meta.env.VITE_ENABLE_LOGGING
    });
} catch (error) {
    console.error('Failed to initialize logger:', error);
} 