export const scrollToTop = () => {
    // Force layout recalculation
    document.body.getBoundingClientRect();

    try {
        // Try scrolling both body and documentElement
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        document.body.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    } catch (error) {
        // Fallback
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
}; 